import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import firebase from "firebase";
import Button from '@material-ui/core/Button';
import {sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CustomizedTable from './CustomizedTable'
import Paper from '@material-ui/core/Paper';
import AutoFitImage from 'react-image-autofit-frame';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import { compose } from "recompose";
import ReactTimeout from 'react-timeout'
import { MovieRounded } from '@material-ui/icons';
import ReactPlayer from 'react-player'
import DayPicker from './DayPicker'
import { Calendar } from 'react-date-range';
import moment from "moment";
import TimeKeeper from 'react-timekeeper';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
    root: {
        width: 1366,
    },
    container: {
      border: '5px solid #f50057'
    },
    mediaContainer: {
      position: 'absolute',
    },
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: 10,
      flex: 1,
    },
});

const windowHeight = window.innerHeight - 120

const SortableItem = sortableElement(({value, index, handleToggle, checked, mediaCollection, allContainer, currentContainer}) => 
{

  return mediaCollection.map(val => {
    if(val.id === value.content){

      if(val.type === "video/mp4"){
        return(
          <ListItem style={{ backgroundColor: value.active ? null : "#ff8d8d" }} disableRipple key={index} selected={allContainer[currentContainer] === value.content ? true : false } divider dense button onClick={handleToggle(val.id)}>
            <Checkbox
            edge="start"
            checked={checked.indexOf(val.id) !== -1}
            tabIndex={-1}
            disableRipple
            />
            <MovieRounded style={{ fontSize: 30, marginLeft:5, marginRight: 5, color: "#b4b4b4" }}/>
            <ListItemText primary={val.name} />
          </ListItem>
        )
      } else {
        return(
          <ListItem  style={{ backgroundColor: value.active ? null : "#ff8d8d" }} disableRipple key={index} selected={allContainer[currentContainer] === value.content ? true : false } divider dense button onClick={handleToggle(val.id)}>
            <Checkbox
              edge="start"
              checked={checked.indexOf(val.id) !== -1}
              tabIndex={-1}
              disableRipple
              />
            <Avatar src={val.imageUrl}>
            </Avatar>
            <ListItemText primary={val.name} />
          </ListItem>
        )
      }
      
    }
  })
})

const SortableContainer = sortableContainer(({children}) => {
  return <List>{children}</List>;
});

var timeout = [];

class CustomizedAddCampaign extends Component {

    state = {
        selectedTemplate: 'default',
        templateCollection: [],
        contents: [[]],
        allContainer: [''],
        openDialog: false,
        openSecondDialog: false,
        dialogSecondOption: '',
        dialogSecondTitle: '',
        dialogOption: '',
        dialogTitle: '',
        mediaData:[],
        folderCollection: [],
        currentContainer: 0,
        checked: [],
        campaignName: '',
        mediaCollection: [],
        contentDuration: 0,
        transitionSelected: 'fade',
        dialogTitleVisible: true,
        currentSlideIndex: [0],
        created: null,
        currentCampaignId: null,
        selectedStartDate:  moment(new Date()),
        selectedEndDate: moment(new Date()),
        selectedStartTime: '12:00 am',
        selectedEndTime: '11:59 pm',
        selectedSecondStartDate:  moment(new Date()),
        selectedSecondEndDate: moment(new Date()),
        selectedSecondStartTime: '12:00 am',
        selectedSecondEndTime: '11:59 pm',
        switchChecked: false,
        switchSecondChecked: false,
        stretchValue: true,
        stretchSelected: false,
        fullScreenDialog: false,
        currentSelectedContent: 0

    }

    componentWillMount(){

      let folderRef = firebase.database().ref('media_folder');

          folderRef.once('value', foldersnapshot => {
            const folderCollection = [];

            foldersnapshot.forEach(childFolderSnapshot => {
                const folderItem = childFolderSnapshot.val();
                folderItem.key = childFolderSnapshot.key;
                
                folderCollection.push({ id:folderItem.key, name: folderItem.name, created: folderItem.created, total: folderItem.total });
                
            });

            let ref = firebase.database().ref('media');

            ref.once('value', snapshot => {
                const mediaCollection = [];

                snapshot.forEach(childSnapshot => {
                  const item = childSnapshot.val();
                  item.key = childSnapshot.key;
                  
                  mediaCollection.push({id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder });
                  
                });

              

                let templateRef = firebase.database().ref('template');

                templateRef.once('value', templateSnapshot => {
                    const templateCollection = [];

                    templateSnapshot.forEach(templateChildSnapshot => {
                        const templateItem = templateChildSnapshot.val();
                        templateItem.key = templateChildSnapshot.key;
                        templateCollection.push({ id:templateItem.key, name: templateItem.name, created: templateItem.created, container: templateItem.container });
                    });

                    this.storeCollection(folderCollection, mediaCollection, templateCollection)
                });
            });
      })


      let folderRef2 = firebase.database().ref('media_folder');

        folderRef2.on('value', foldersnapshot => {
            const folderCollection = [];

            foldersnapshot.forEach(childFolderSnapshot => {
                const folderItem = childFolderSnapshot.val();
                folderItem.key = childFolderSnapshot.key;
                
                folderCollection.push({ id:folderItem.key, name: folderItem.name, created: folderItem.created, total: folderItem.total });
                
            });

            let ref = firebase.database().ref('media');

            ref.on('value', snapshot => {
                const mediaCollection = [];

                snapshot.forEach(childSnapshot => {
                  const item = childSnapshot.val();
                  item.key = childSnapshot.key;
                  
                  mediaCollection.push({id:item.key, name: item.name, imageUrl: item.imageUrl, created: item.created, type: item.type, size: item.size, folder: item.folder });
                  
                });

              

                let templateRef = firebase.database().ref('template');

                templateRef.once('value', templateSnapshot => {
                    const templateCollection = [];

                    templateSnapshot.forEach(templateChildSnapshot => {
                        const templateItem = templateChildSnapshot.val();
                        templateItem.key = templateChildSnapshot.key;
                        templateCollection.push({ id:templateItem.key, name: templateItem.name, created: templateItem.created, container: templateItem.container });
                    });

                    this.setState({ folderCollection, mediaCollection, templateCollection });
                });
            });
      })
      
    }

    storeCollection = (folderCollection,  mediaCollection, templateCollection) =>{

      //edit campaign
      if(this.props.editCampaign){
        let ref = firebase.database().ref('campaign/'+ this.props.editCampaignId);

        ref.once('value', snapshot => {
          const item = snapshot.val();
          item.key = snapshot.key;
          const campaignName = item.name

          const contents = []
          const allContainer = []
          const currentSlideIndex = []

          if(item.template !== 'default')
          {
            const currentIndex = templateCollection.map(value => { return value.id}).indexOf(item.template)
            templateCollection[currentIndex].container.map((value, index) => { 

              if(item.content && item.content[index])
              {
                allContainer.push(item.content[index][0].content)
                contents.push(item.content[index])
              } else {
                allContainer.push("")
                contents.push([])
              }

              currentSlideIndex.push(0)
            })
            
            this.setState({ switchChecked: item.schedule ? true : false, 
              selectedStartDate: item.schedule ? moment.unix(item.schedule.start_date) : moment(new Date()),
              selectedEndDate: item.schedule ? moment.unix(item.schedule.end_date) : moment(new Date()),
              selectedStartTime: item.schedule ? item.schedule.start_time : '12:00 am',
              selectedEndTime: item.schedule ? item.schedule.end_time : '11:59 pm',
              selectedSecondStartDate: item.content && item.content[0] && item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.start_date) : moment(new Date()),
              selectedSecondEndDate: item.content && item.content[0] && item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.end_date) : moment(new Date()),
              selectedSecondStartTime: item.content && item.content[0] && item.content[0][0].schedule ? item.content[0][0].schedule.start_time :'12:00 am',
              selectedSecondEndTime: item.content && item.content[0] && item.content[0][0].schedule ? item.content[0][0].schedule.end_time :'12:00 am',
              switchSecondChecked: item.content && item.content[0] && item.content[0][0].schedule ? true : false,
              currentCampaignId: item.key, 
              created: item.created, campaignName, 
              contentDuration: item.content && item.content[0] && item.content[0][0].duration? item.content[0][0].duration : null, 
              transitionSelected: item.content && item.content[0] ? item.content[0][0].transition : 'fade', 
              folderCollection, mediaCollection, templateCollection, selectedTemplate: item.template, allContainer, contents: contents, currentSlideIndex });
            
          } else {

            currentSlideIndex.push(0)

            if(item.content && item.content[0])
            {
              allContainer.push(item.content[0][0].content)
            } else {
              allContainer.push('')
            }

            item.content[0].map(value => { 
              if(value.schedule){
                if(moment(moment(new Date()).format('YYYY-MM-DD')).isAfter(moment.unix(value.schedule.end_date))) {
                  value.active = false
                } else {
                  value.active = true
                }
              } else {
                value.active = true
              }
            })

            
            this.setState({ switchChecked: item.schedule ? true : false, 
              selectedStartDate: item.schedule ? moment.unix(item.schedule.start_date) : moment(new Date()),
              selectedEndDate: item.schedule ? moment.unix(item.schedule.end_date) : moment(new Date()),
              selectedStartTime: item.schedule ? item.schedule.start_time : '12:00 am',
              selectedEndTime: item.schedule ? item.schedule.end_time : '11:59 pm',
              selectedSecondStartDate: item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.start_date) : moment(new Date()),
              selectedSecondEndDate: item.content[0][0].schedule ? moment.unix(item.content[0][0].schedule.end_date) : moment(new Date()),
              selectedSecondStartTime: item.content[0][0].schedule ? item.content[0][0].schedule.start_time :'12:00 am',
              selectedSecondEndTime: item.content[0][0].schedule ? item.content[0][0].schedule.end_time :'11:59 pm',
              switchSecondChecked: item.content[0][0].schedule ? true : false,
              currentCampaignId: item.key, 
              created: item.created, campaignName, 
              contentDuration: item.content && item.content[0][0].duration? item.content[0][0].duration : null, 
              transitionSelected: item.content?item.content[0][0].transition : 'fade',  
              folderCollection, mediaCollection, templateCollection, selectedTemplate: item.template, allContainer, contents: item.content ? item.content : [[]], currentSlideIndex  });
          }

        });
      } else {
        this.setState({ folderCollection, mediaCollection, templateCollection });
      }
    }

    handleTemplateChange = event => {
        const contents = []
        const allContainer = []
        const currentSlideIndex = []
        if(event.target.value !== 'default')
        {
          this.state.templateCollection.map(value => { 
            if(event.target.value === value.id){
              value.container.map(value => { 
                contents.push([])
                allContainer.push('')
                currentSlideIndex.push(0)
              })
            }
          })
        } else {
          contents.push([])
          allContainer.push('')
          currentSlideIndex.push(0)
        }

        this.setState({ selectedTemplate: event.target.value, contents, allContainer, currentContainer: 0, contentDuration: 0, transitionSelected: 'fade', currentSlideIndex })
    }

    handleToggle = value => () => {
      
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
          checked: newChecked,
        });
    };

    onSortEnd = ({oldIndex, newIndex}) => {

        var itemsArray = [[]]
        var finalItemsArray = [[]]
  
        finalItemsArray = this.state.contents
        itemsArray = arrayMove(this.state.contents[this.state.currentContainer], oldIndex, newIndex)
        
        finalItemsArray.map((value, index) => {
          if(index === this.state.currentContainer){
            finalItemsArray[index] = itemsArray
          }
        })
        
        this.setState({ contents: finalItemsArray });
    };
  
    onSortStart = ({node, index, collection, isKeySorting}) => {
    
        const allContainer = this.state.allContainer.map((value, num) => {
            
            if(num == this.state.currentContainer)
            {
            return this.state.contents[this.state.currentContainer][index].content
            } else {
            return this.state.allContainer[num]
            }
        })

        this.setState({ currentSelectedContent: index })

        if(this.state.contents[this.state.currentContainer][index].type === 'video/mp4')
        {
          if(this.state.contents[this.state.currentContainer][index].schedule){

            this.setState({ 
              selectedSecondStartDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.start_date), 
              selectedSecondEndDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.end_date),
              selectedSecondStartTime: this.state.contents[this.state.currentContainer][index].schedule.start_time,
              selectedSecondEndTime: this.state.contents[this.state.currentContainer][index].schedule.end_time,
              switchSecondChecked: true, allContainer, contentDuration: null, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          } else {
            this.setState({ 
              selectedSecondStartDate:  moment(new Date()),
              selectedSecondEndDate: moment(new Date()),
              selectedSecondStartTime: '12:00 am',
              selectedSecondEndTime: '11:59 pm',
              switchSecondChecked: false, allContainer, contentDuration: null, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          }
          
        } else {

          if(this.state.contents[this.state.currentContainer][index].schedule){
            this.setState({ 
              selectedSecondStartDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.start_date), 
              selectedSecondEndDate: moment.unix(this.state.contents[this.state.currentContainer][index].schedule.end_date),
              selectedSecondStartTime: this.state.contents[this.state.currentContainer][index].schedule.start_time,
              selectedSecondEndTime: this.state.contents[this.state.currentContainer][index].schedule.end_time,
              switchSecondChecked: true, allContainer, contentDuration: this.state.contents[this.state.currentContainer][index].duration, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          } else {
            this.setState({ 
              selectedSecondStartDate:  moment(new Date()),
              selectedSecondEndDate: moment(new Date()),
              selectedSecondStartTime: '12:00 am',
              selectedSecondEndTime: '11:59 pm',
              switchSecondChecked: false, allContainer, contentDuration: this.state.contents[this.state.currentContainer][index].duration, transitionSelected: this.state.contents[this.state.currentContainer][index].transition })
          }
         
        }
        
    }

    handleClose = () => {
        
        const currentSlideIndex = []
        if(this.state.selectedTemplate !== 'default'){
          const currentIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.selectedTemplate)
          this.state.templateCollection[currentIndex].container.map((val, index) => {
            clearTimeout(timeout[index])
            timeout[index] = null
            currentSlideIndex.push(0)
          })
        } else {
            clearTimeout(timeout[0])
            timeout[0] = null
            currentSlideIndex.push(0)
        }

        this.setState({ openDialog: false, currentSlideIndex });
    };

    handleSecondClose = () => {
      this.setState({ openSecondDialog: false });
    }

    renderSortableItem = () => {
      if(this.state.contents[this.state.currentContainer])
      {
        return this.state.contents[this.state.currentContainer].map((val,index)=>{
            return(
              <SortableItem key={`item-${index}`} index={index} value={val} handleToggle={this.handleToggle} checked={this.state.checked} mediaCollection={this.state.mediaCollection} currentContainer={this.state.currentContainer} allContainer={this.state.allContainer}/>
            )
          })
      } else {
          return(
            <div></div>
          )
      }
    }

    renderSortable = () => {
        return (
            <SortableContainer lockAxis="y" onSortEnd={this.onSortEnd} onSortStart={this.onSortStart}>
                <Divider/>
                <List>
                {this.renderSortableItem()}
                </List>
            </SortableContainer>
        )
    }

    renderTemplate = () => {

      if(this.state.selectedTemplate !== 'default'){
        
        const templateData = [];
        this.state.templateCollection.map((value, index) => {
          if(value.id === this.state.selectedTemplate)
          {
            value.container.map((val, index) => {
              templateData.push({ id:val.id, width: val.width, height: val.height })
            })
            
          }
        })

        const mediaData = [];
        
        this.state.allContainer.map((value, index) => { 
          
          if(this.state.allContainer[index] !== '')
          {

            this.state.mediaCollection.map((value, mediaIndex) => {
              if(value.id === this.state.allContainer[index])
              {
                mediaData.push({ id: value.id, name: value.name, imageUrl: value.imageUrl, type: value.type })
              }
            })

          } else {
            mediaData.push({ id: '', name: '', imageUrl: '' })
          }
          
        })
        
        return templateData.map((value, index) => {

          if(mediaData[index].type === "video/mp4")
          {
            return (
              <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} onClick={()=> { 
    
                  if(this.state.currentContainer !== index)
                  {
                    const currentContent = this.state.allContainer[index]
                    const currenIndex = this.state.contents[index].map(val => { return val.content }).indexOf(currentContent)
                    
                    if(currenIndex !== -1){

                      if(this.state.contents[index][currenIndex].schedule){
                        this.setState({ 
                          selectedSecondEndDate: moment.unix(this.state.contents[index][currenIndex].schedule.end_date),
                          selectedSecondStartDate: moment.unix(this.state.contents[index][currenIndex].schedule.start_date),
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.start_time,
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.end_time,
                          switchSecondChecked: true, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: null });
                      } else {
                        this.setState({ switchSecondChecked: false, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: null });
                      }
                      
                    } else {
                      this.setState({ currentContainer: index, checked: [] }) 
                    }
    
                  }
                  
                }} item>
                <Paper className={ this.state.currentContainer === index ? this.props.classes.container : null } square style={{height: '100%', width:'100%', display: 'flex', justifyContent: 'center'}} >
                  <video style={{ width: '100%' }}  autoPlay src={mediaData[index] ? mediaData[index].imageUrl : ''} type="video/mp4"></video>
                </Paper>
              </Grid> )
          } else {
            return (
              <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} onClick={()=> { 
    
                  if(this.state.currentContainer !== index)
                  {
                    const currentContent = this.state.allContainer[index]
                    const currenIndex = this.state.contents[index].map(val => { return val.content }).indexOf(currentContent)
                    
                    if(currenIndex !== -1){
                      if(this.state.contents[index][currenIndex].schedule){
                        this.setState({ 
                          selectedSecondEndDate: moment.unix(this.state.contents[index][currenIndex].schedule.end_date),
                          selectedSecondStartDate: moment.unix(this.state.contents[index][currenIndex].schedule.start_date),
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.start_time,
                          selectedSecondStartTime: this.state.contents[index][currenIndex].schedule.end_time,
                          switchSecondChecked: true, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: this.state.contents[index][currenIndex].duration });
                      } else {
                        this.setState({ switchSecondChecked: false, currentContainer: index, checked: [], transitionSelected: this.state.contents[index][currenIndex].transition, contentDuration: this.state.contents[index][currenIndex].duration });
                      }
                    } else {
                     
                      this.setState({ currentContainer: index, checked: [] }) 
                    }
    
                  }
                  
                }} item>
                <Paper className={ this.state.currentContainer === index ? this.props.classes.container : null } square style={{height: '100%', width:'100%'}} >
                  <AutoFitImage imgSize="contain" imgSrc={mediaData[index] ? mediaData[index].imageUrl : ''} style={{/*..your style here..*/}}/>
                </Paper>
              </Grid> )
          }
        })

      } else {

        const mediaData = [];
        
        this.state.allContainer.map((value, index) => { 
          
          if(this.state.allContainer[index] && this.state.allContainer[index] !== '')
          {
            
            this.state.mediaCollection.map((val, mediaindex) => {
              if(val.id === this.state.allContainer[index])
              {
                mediaData.push({ id: val.id, name: val.name, imageUrl: val.imageUrl, type: val.type })
              }
            })
          } else {
            mediaData.push({ id: '', name: '', imageUrl: '' })
          }
          
        })
       
        if(mediaData[0] && mediaData[0].type === "video/mp4")
        {
          return (
            <Grid item>
              <Paper square
                style={{ height: 1920/4, width:1080/4, display: 'flex', justifyContent: 'center' }}
              >
                <video  style={{ width: '100%' }}  autoPlay src={mediaData[0] ? mediaData[0].imageUrl : ''} type="video/mp4"></video>
              </Paper>
            </Grid>)
        } else {

          if(mediaData[0] && mediaData[0].stretch){
            return (
              <Grid item>
                <Paper square
                  style={{ height: 1920/4, width:1080/4 }}
                >
                  <img style={{ width: '100%', height: '100%'}} src={mediaData[0] ? mediaData[0].imageUrl : ''}/>
                </Paper>
              </Grid>)
          } else {
            return (
              <Grid item>
                <Paper square
                  style={{ height: 1920/4, width:1080/4 }}
                >
                  <AutoFitImage imgSize="contain" frameWidth={1080/4 + "px"} frameHeight={1920/4 + "px"} imgSrc={mediaData[0] ? mediaData[0].imageUrl : ''} style={{/*..your style here..*/}}/>
                </Paper>
              </Grid>)
          }
        }
        
      }
      
    }

    renderDialog = () => {
        if(this.state.dialogOption === 'addMedia'){
            return(
                <div>
                    <DialogContent style={{ paddingTop: 20, paddingLeft: 0, paddingRight:0}}>
                    <CustomizedTable 
                        rows={[
                            { id: 'name', numeric: false, disablePadding: true, label: 'Name', sortable: true },
                            { id: 'imageUrl', numeric: false, disablePadding: false, label: 'File Thumbnail', sortable: true, thumbnail: true },
                        ]} 
                        orderBy={'created'}
                        folderCollection={this.state.folderCollection}
                        preview={true}
                        movefile={true}
                        select={true}
                        dataName='media'
                        folderSelection={true}
                        onAddMediaClick={this.onAddMediaClick}/>
                    </DialogContent>
                </div>
            )
        } else if (this.state.dialogOption === 'preview'){

          if(this.state.selectedTemplate !== 'default'){

            const templateData = [];
            this.state.templateCollection.map((value, index) => {
              if(value.id === this.state.selectedTemplate)
              {
                value.container.map((val, index) => {
                  templateData.push({ id:val.id, width: val.width, height: val.height })
                })
              }
            })

            return(
              <DialogContent style={{ backgroundColor: '#efefef', paddingTop: 25 }}>
                {templateData.map((value, index) => {
                  return (
                      <Grid key={value.id} style={{height: value.height/4, width:value.width/4}} item>
                        {this.renderListAllCarouselItem( index, value.width/4, value.height/4 )}
                      </Grid>
                    )
                })}
              </DialogContent>
            )
            
          } else {
            return (
              <DialogContent style={{ backgroundColor: '#efefef', paddingTop: 25 }}>
                <Grid style={{height: 1920/4, width: 1080/4}} item>
                  {this.renderListAllCarouselItem(0, 1080/4, 1920/4)}
                </Grid>
              </DialogContent>)
          }

        }

        else if (this.state.dialogOption === 'edit'){

          return (
            <DialogContent >
              <Grid container>
                <DayPicker 
                  switchChecked = {this.state.switchSecondChecked}
                  handleSwitchChange={() => this.handleSecondSwitchChange()}
                  selectedStartTime={this.state.selectedSecondStartTime} 
                  selectedEndTime={this.state.selectedSecondEndTime} 
                  selectedStartDate={this.state.selectedSecondStartDate} 
                  selectedEndDate={this.state.selectedSecondEndDate} 
                  openDialogStartDate={() => this.openSecondDialogStartDate()} 
                  openDialogEndDate={() => this.openSecondDialogEndDate()} 
                  openDialogStartTime={() => this.openSecondDialogStartTime()} 
                  openDialogEndTime={() => this.openSecondDialogEndTime()} 
                  closeDialog={() => this.closeDialog()}/>
              </Grid>
            </DialogContent>
          )
        }
    }

    renderScheduleDialog = () => {
      if (this.state.dialogSecondOption === 'StartDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedStartDate}
                onChange={this.handleStartDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'EndDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedEndDate}
                onChange={this.handleEndDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'StartTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedStartTime}
                onChange={this.handleStartTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'EndTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedEndTime}
                onChange={this.handleEndTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondStartDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedSecondStartDate}
                onChange={this.handleSecondStartDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondEndDate'){
        return (
          <DialogContent>
            <Calendar
                date={this.state.selectedSecondEndDate}
                onChange={this.handleSecondEndDateSelect}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondStartTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedSecondStartTime}
                onChange={this.handleSecondStartTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }

      else if (this.state.dialogSecondOption === 'SecondEndTime'){
        return (
          <DialogContent>
            <TimeKeeper
                time={this.state.selectedSecondEndTime}
                onChange={this.handleSecondEndTimeSelect}
                config={{
                    TIMEPICKER_BACKGROUND: 'white',
                    DONE_BUTTON_COLOR: '#64c9f1',
                    DONE_BUTTON_BORDER_COLOR: '#ededed'
                }}
                onDoneClick={() => this.closeDialog()}
            />
          </DialogContent>
        )
      }
    }

    handleStartDateSelect = date => {
      this.setState({selectedStartDate:date, openSecondDialog: false })
    }

    handleEndDateSelect = date => {
      this.setState({selectedEndDate:date, openSecondDialog: false })
    }

    handleStartTimeSelect = time => {
      this.setState({selectedStartTime:time.formatted })
    }

    handleEndTimeSelect = time => {
      this.setState({selectedEndTime:time.formatted })
    }

    handleSecondStartDateSelect = date => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1)
      {
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.start_date = date.unix()
      }
      
      this.setState({selectedSecondStartDate:date, openSecondDialog: false, contents: newContents })

    }

    handleSecondEndDateSelect = date => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1)
      {
        
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.end_date = date.unix()
      }
      
      this.setState({selectedSecondEndDate:date, openSecondDialog: false, contents: newContents })

    }

    handleSecondStartTimeSelect = time => {

      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1){
        
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.start_time = time.formatted
      }
      
      this.setState({selectedSecondStartTime:time.formatted, contents: newContents })

    }

    handleSecondEndTimeSelect = time => {
      
      const newContents = [...this.state.contents]
      if(this.state.currentSelectedContent !== -1){
        newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule.end_time = time.formatted
      }
      
      this.setState({selectedSecondEndTime:time.formatted, contents: newContents })

    }


    renderListAllCarouselItem = (cIndex, itemWidth, itemHeight) => {
        return this.state.contents[cIndex].map((value, index) => { 

          const currentIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(value.content)

          if(value.transition === 'none'){
            return <Fade key={index} timeout={0} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              </Paper>
            </Fade>
          } else if(value.transition === 'fade'){
            return <Fade key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              </Paper>
            </Fade>
          } else if(value.transition === 'zoom'){
            return <Zoom key={index} timeout={1000} in={this.state.currentSlideIndex[cIndex] === index ? true : false} >
              <Paper square className={this.props.classes.mediaContainer} style={{ height: itemHeight, width:itemWidth, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
              {this.state.mediaCollection[currentIndex].type === "video/mp4" ? <ReactPlayer width='100%' height="100%" loop={this.state.contents[cIndex].length > 1 ? false : true} playing={this.state.currentSlideIndex[cIndex] === index ? true : false} url={this.state.mediaCollection[currentIndex].imageUrl} onEnded={()=> this.toggleNextSlide(cIndex)}/>:<AutoFitImage imgSize="contain" imgSrc={this.state.mediaCollection[currentIndex].imageUrl}/>}
              </Paper>
            </Zoom>
          } 
        })
    }

    onAddMediaClick = selected => {

      const newContents = [...this.state.contents]
      selected.map((value, index) => {
        const currentIndex = newContents[this.state.currentContainer].map(val => { return val.content }).indexOf(value)
        const currentMediaIndex = this.state.mediaCollection.map(val => { return val.id }).indexOf(value)
        /*if(currentIndex === -1)
        {
          if(this.state.mediaCollection[currentMediaIndex].type === 'video/mp4')
          {
            newContents[this.state.currentContainer].push({ schedule: null, duration: null, transition: 'fade', content: value, type: this.state.mediaCollection[currentMediaIndex].type })
          } else {
            newContents[this.state.currentContainer].push({ schedule: null, duration: 10, transition: 'fade', content: value, type: this.state.mediaCollection[currentMediaIndex].type })
          }
         
        }*/

        if(this.state.mediaCollection[currentMediaIndex].type === 'video/mp4')
          {
            newContents[this.state.currentContainer].push({ schedule: null, duration: null, transition: 'fade', content: value, type: this.state.mediaCollection[currentMediaIndex].type })
          } else {
            newContents[this.state.currentContainer].push({ schedule: null, duration: 10, transition: 'fade', content: value, type: this.state.mediaCollection[currentMediaIndex].type })
          }
      })

      if(this.state.selectedTemplate !== 'default'){
        const allContainer = []
        this.state.templateCollection.map((value, index) => {
          if(value.id === this.state.selectedTemplate)
          {
            value.container.map((val, index) => {
              if(this.state.allContainer[index] == '' && index == this.state.currentContainer)
              {
                allContainer.push(newContents[this.state.currentContainer][0].content)
              } else {
                allContainer.push(this.state.allContainer[index])
              }
            })
            
          }
        })

        this.setState({ contents: newContents, openDialog: false, allContainer, contentDuration: newContents[this.state.currentContainer][0].duration, transitionSelected: newContents[this.state.currentContainer][0].transition })
      } else {
        const allContainer = []
        allContainer.push(newContents[this.state.currentContainer][0].content)

        this.setState({ contents: newContents, openDialog: false, allContainer, contentDuration: newContents[this.state.currentContainer][0].duration, transitionSelected: newContents[this.state.currentContainer][0].transition })
      }

    }

    handleCampaignNameChange = event => {
      this.setState({ campaignName: event.target.value });
    };  

    handleSaveCampaign = () => {
      
      if(this.state.selectedTemplate !== 'default' )
      {
        let templateRef = firebase.database().ref('template/' + this.state.selectedTemplate);

        templateRef.once('value', snapshot => {
          const item = snapshot.val();

          var scheduleContent = {
            start_date: this.state.selectedStartDate.unix() ,
            end_date: this.state.selectedEndDate.unix(),
            start_time: this.state.selectedStartTime,
            end_time: this.state.selectedEndTime
          }
  
          var postData = {
            name: this.state.campaignName == ''? 'default' : this.state.campaignName,
            created: this.props.editCampaign ? this.state.created : firebase.database.ServerValue.TIMESTAMP,
            updated: firebase.database.ServerValue.TIMESTAMP,
            content: this.state.contents,
            template: this.state.selectedTemplate,
            template_name: item.name,
            schedule: this.state.switchChecked ? scheduleContent : null
          };
      
          // Get a key for a new Post.
          if(this.props.editCampaign){

            // Write the new post's data simultaneously in the posts list and the user's post list.
            var updates = {};
            updates['/campaign/' + this.state.currentCampaignId] = postData;
          
            firebase.database().ref().update(updates);
          } else {
            var newPostKey = firebase.database().ref().child('campaign').push().key;
      
            // Write the new post's data simultaneously in the posts list and the user's post list.
            var updates = {};
            updates['/campaign/' + newPostKey] = postData;
          
            firebase.database().ref().update(updates);
          }
            
        });
      } else {

        var scheduleContent = {
          start_date: this.state.selectedStartDate.unix() ,
          end_date: this.state.selectedEndDate.unix(),
          start_time: this.state.selectedStartTime,
          end_time: this.state.selectedEndTime
        }

        var postData = {
          name: this.state.campaignName == ''? 'default' : this.state.campaignName,
          created: this.props.editCampaign ? this.state.created : firebase.database.ServerValue.TIMESTAMP,
          updated: firebase.database.ServerValue.TIMESTAMP,
          content: this.state.contents,
          template: this.state.selectedTemplate,
          template_name: 'default',
          schedule: this.state.switchChecked ? scheduleContent : null
        };
    
        // Get a key for a new Post.
        if(this.props.editCampaign){
          
          // Write the new post's data simultaneously in the posts list and the user's post list.
          var updates = {};
          updates['/campaign/' + this.state.currentCampaignId] = postData;
        
          firebase.database().ref().update(updates);
        } else {
          var newPostKey = firebase.database().ref().child('campaign').push().key;
    
          // Write the new post's data simultaneously in the posts list and the user's post list.
          var updates = {};
          updates['/campaign/' + newPostKey] = postData;
        
          firebase.database().ref().update(updates);
        }
      }
      this.props.handleTabsChangeIndex(0)
      
      this.setState({ selectedTemplate:'default', contents: [[]], campaignName: '', allContainer: [], currentContainer: 0, transitionSelected: 'fade', contentDuration: 0, currentSlideIndex: [0] })
    }

    onRemoveSortableListItem = () => {
      const { checked } = this.state;
      const newAllContainer = [...this.state.allContainer];
      const newContents = [...this.state.contents];

      checked.map(value => {

        const currentIndex = newContents[this.state.currentContainer].map(val => { return val.content }).indexOf(value)

        if (currentIndex !== -1) {
          newContents[this.state.currentContainer].splice(currentIndex, 1);
        } 

        if(newAllContainer[this.state.currentContainer] === value){
          newAllContainer[this.state.currentContainer] = ''
        }

      })

      this.setState({ contents: newContents, checked: [], allContainer: newAllContainer })
    }

    onEditSortableListItem = () => {
      this.setState({ 
        openDialog: true, 
        dialogOption: 'edit', 
        dialogTitle: 'Edit File',
        fullScreenDialog: false, 
        dialogTitleVisible: true
      })
    }

    handleContentDurationChange = name => event => {

      const currentContent = this.state.allContainer[this.state.currentContainer]
      const currenIndex = this.state.contents[this.state.currentContainer].map(val => { return val.content }).indexOf(currentContent)

      if(currenIndex !== -1){
        if(event.target.value >= 0 ){
          this.state.contents[this.state.currentContainer][currenIndex].duration = event.target.value
          this.setState({ [name]: event.target.value });
        } else {
          this.state.contents[this.state.currentContainer][currenIndex].duration = 0
          this.setState({ [name]: 0 });
        }
      } else {
        if(event.target.value >= 0 ){
          this.setState({ [name]: event.target.value });
        } else {
          this.setState({ [name]: 0 });
        }
      }
      
    }

    handleTransitionChange = event => {
      const currentContent = this.state.allContainer[this.state.currentContainer]
      const currenIndex = this.state.contents[this.state.currentContainer].map(val => { return val.content }).indexOf(currentContent)
      
      if(currenIndex !== -1){
        this.state.contents[this.state.currentContainer][currenIndex].transition = event.target.value
        this.setState({ transitionSelected: event.target.value });
      } else {
        this.setState({ transitionSelected: event.target.value });
      }

    }

    handleStretchChange = event => {

      const currentContent = this.state.allContainer[this.state.currentContainer]
      const currenIndex = this.state.contents[this.state.currentContainer].map(val => { return val.content }).indexOf(currentContent)
      
      if(currenIndex !== -1){
        this.state.contents[this.state.currentContainer][currenIndex].stretch = event.target.checked
        this.setState({ stretchSelected: event.target.checked });
      } else {
        this.setState({ stretchSelected: event.target.checked });
      }

    }
    
    renderTransition(){
      return(
        <Grid container>
          <Grid item xs={3} style={{ paddingRight: 5 }}>
            <TextField
              disabled={this.state.contents[this.state.currentContainer].length > 0 && this.state.contentDuration !== null? false:true}
              fullWidth 
              id="outlined-number"
              label="Duration"
              value={this.state.contentDuration === null ? 0 : this.state.contentDuration}
              InputProps={{ inputProps: { min: 0, max: 3600 } }}
              onChange={this.handleContentDurationChange('contentDuration')}
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 5, paddingRight: 5 }}>
            <form autoComplete="off">
              <FormControl margin="normal" fullWidth variant="outlined" >
                <InputLabel
                  htmlFor="outlined-age-simple"
                >
                  Transition
                </InputLabel>
                <Select
                  disabled={this.state.contents[this.state.currentContainer].length > 0 ? false:true}
                  value={this.state.transitionSelected}
                  onChange={this.handleTransitionChange}
                  input={
                    <OutlinedInput
                      fullWidth
                      labelWidth={100}
                      name="age"
                      id="outlined-age-simple"
                    />
                  }
                >
                  <MenuItem value="none">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="fade">Fade</MenuItem>
                  <MenuItem value="zoom">Zoom</MenuItem>
                </Select>
              </FormControl>
            </form>
          </Grid>
          <Grid item xs={3} style={{ display: "flex", paddingLeft: 5 }}>
            <FormControl>
              <FormLabel style={{ paddingLeft: 15, paddingTop:10, fontSize: 12 }}>Stretch</FormLabel>
              <FormGroup style={{ paddingLeft: 15 }} aria-label="position" name="position" value={this.state.stretchValue} onChange={this.handleStretchChange} row>
                <FormControlLabel
                  value="stretch"
                  control={<Checkbox color="primary" />}
                  disabled={this.state.contents[this.state.currentContainer].length > 0 ? false:true}  
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      )
    }

    onPreviewClick = () => {
      this.setState({ 
        openDialog: true, 
        dialogOption: 'preview', 
        dialogTitle: 'Preview',
        fullScreenDialog: false, 
        dialogTitleVisible: false
      })
      
      this.shiftSlide()
    }

    toggleSlide = (index) => event =>{
      
      
      if(this.state.currentSlideIndex[index] < this.state.contents[index].length - 1){
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = newCurrentSlide[index] + 1
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopSlide(index)
        }
      } else {
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = 0
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopSlide(index)
        }
      }
     
    } 

    toggleNextSlide = (index) => {
      
      
      if(this.state.currentSlideIndex[index] < this.state.contents[index].length - 1){
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = newCurrentSlide[index] + 1
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopNextSlide(index, nextSlideIndex)
        }
      } else {
        const newCurrentSlide = [...this.state.currentSlideIndex]
        newCurrentSlide[index] = 0
        this.setState({ currentSlideIndex: newCurrentSlide })

        const nextSlideIndex = newCurrentSlide[index]
        if(this.state.contents[index][nextSlideIndex].type !== 'video/mp4' ){
          this.loopNextSlide(index, nextSlideIndex)
        }
      }
     
    } 

    loopNextSlide = (index, nextSlideIndex) => {
      timeout[index] = this.props.setTimeout(this.toggleSlide(index, this.state.contents[index].length), this.state.contents[index][nextSlideIndex].duration * 1000)
    }

    loopSlide = (index) => {
      timeout[index] = this.props.setTimeout(this.toggleSlide(index, this.state.contents[index].length), this.state.contents[index][this.state.currentSlideIndex[index]].duration * 1000)
    }

    shiftSlide = () => {

      if(this.state.selectedTemplate !== 'default')
      {
        const currentIndex = this.state.templateCollection.map(val => { return val.id }).indexOf(this.state.selectedTemplate)
        this.state.templateCollection[currentIndex].container.map((val, index) => {
          if(this.state.contents[index].length > 1){
            if(this.state.contents[index][0].type !== 'video/mp4')
            {
              timeout[index] = this.props.setTimeout(this.toggleSlide(index), this.state.contents[index][this.state.currentSlideIndex[index]].duration * 1000)
            } 
          }
        })

      } else {
        if( this.state.contents[0].length > 1 && this.state.contents[0][0].type !== 'video/mp4'){
          timeout[0] = this.props.setTimeout(this.toggleSlide(0), this.state.contents[0][this.state.currentSlideIndex[0]].duration * 1000)
        }
      }
      
    }  

    onClearClick = () => {
      const contents = []
      const allContainer = []
      this.state.contents.map(val => {
        contents.push([])
        allContainer.push('')
      })

      this.setState({ contents, campaignName: '', allContainer, currentContainer: 0, transitionSelected: 'fade', contentDuration: 0, currentSlideIndex: [0] })
    }

    disablePreview = () => {

      let checkedContent = true

      this.state.contents.map(val=> {
        
        if(val.length > 0){
          checkedContent = false
        }

      })

      return checkedContent
    }

    openDialogStartDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'StartDate', 
        dialogSecondTitle: 'Start Date'
      })
    }

    openDialogEndDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'EndDate', 
        dialogSecondTitle: 'End Date'
      })
    }

    openDialogStartTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'StartTime', 
        dialogSecondTitle: 'Start Time'
      })
    }

    openDialogEndTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'EndTime', 
        dialogSecondTitle: 'End Time'
      })
    }

    openSecondDialogStartDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondStartDate', 
        dialogSecondTitle: 'Start Date'
      })
    }

    openSecondDialogEndDate = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondEndDate', 
        dialogSecondTitle: 'End Date'
      })
    }

    openSecondDialogStartTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondStartTime', 
        dialogSecondTitle: 'Start Time'
      })
    }

    openSecondDialogEndTime = () => {
      this.setState({ 
        openSecondDialog: true, 
        dialogSecondOption: 'SecondEndTime', 
        dialogSecondTitle: 'End Time'
      })
    }

    closeDialog = () => {
      this.setState({ 
        openSecondDialog: false,
      })
    }

    handleSwitchChange = () => {
      this.setState(state => ({ switchChecked: !state.switchChecked }));
    }

    handleSecondSwitchChange = () => {

      if(!this.state.switchSecondChecked)
      {
        const newContents = [...this.state.contents];
        var scheduleContent = {
          start_date: this.state.selectedSecondStartDate.unix(),
          end_date: this.state.selectedSecondEndDate.unix(),
          start_time: this.state.selectedSecondStartTime,
          end_time: this.state.selectedSecondEndTime,
        }
        
        if(this.state.currentSelectedContent !== -1)
        {
          newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule = scheduleContent
        }
        
        this.setState(state => ({ switchSecondChecked: !state.switchSecondChecked, contents: newContents }));

      } else {
        const newContents = [...this.state.contents];
        if(this.state.currentSelectedContent !== -1)
        {
          newContents[this.state.currentContainer][this.state.currentSelectedContent].schedule = null
        }
        

        this.setState(state => ({ switchSecondChecked: !state.switchSecondChecked, contents: newContents }));
      }
      
    }

    render() {
        const { classes } = this.props;

        return (
          <div className={classes.root}>
            <Dialog
                onClose={this.handleSecondClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.openSecondDialog}
                maxWidth='xl'
            >
            <DialogTitle id="customized-dialog-title" onClose={this.handleSecondClose}>
                {this.state.dialogSecondTitle}
            </DialogTitle>
            
            {this.renderScheduleDialog()}
            </Dialog>
            <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.openDialog}
                maxWidth='xl'
                fullScreen={this.state.fullScreenDialog}
            >{this.state.dialogTitleVisible && !this.state.fullScreenDialog ? 
            <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
                {this.state.dialogTitle}
            </DialogTitle> : <div></div>}

            {this.state.fullScreenDialog ? 
            <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography style={{ color:'white' }} variant="h6" className={classes.title}>
                {this.state.dialogTitle}
              </Typography>
            </Toolbar>
          </AppBar> : <div></div>}
            
            {this.renderDialog()}
            </Dialog>
            <Grid style={{ minHeight:windowHeight }} container>
                <Grid style={{ height: windowHeight, padding: 10 }} item xs={3}>
                    <FormControl style={{ marginTop: 10 }} fullWidth variant="outlined" >
                        <InputLabel
                            ref={ref => {
                            this.InputLabelRef = ref;
                            }}
                            htmlFor="outlined-age-simple"
                        >
                            Template
                        </InputLabel>
                        <Select
                            value={this.state.selectedTemplate}
                            onChange={this.handleTemplateChange}
                            input={
                            <OutlinedInput
                                labelWidth={100}
                                name="folder"
                                id="outlined-age-simple"
                            />
                            }
                        >
                            
                            <MenuItem value="default">
                            <em>Default</em>
                            </MenuItem>
                            {this.state.templateCollection.map(n => {
                            return(
                                <MenuItem key={n.id} value={n.id}>{n.name}</MenuItem>
                            )
                            })}

                        </Select>
                    </FormControl>
                    <Grid container>
                      <Grid container>
                        <Grid item xs={6} style={{ paddingRight: 5 }}>
                          <Button fullWidth style={{ marginTop: 10 }} variant="outlined" color="primary" onClick={()=> this.setState({ openDialog: true, fullScreenDialog: true, dialogOption: 'addMedia', dialogTitle: 'Add Media', dialogTitleVisible: true }) }>
                          Add File
                          </Button>
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 5, paddingRight: 5 }}>
                          <Button disabled={this.state.contents[this.state.currentContainer].length > 0 ? false : true } fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onEditSortableListItem() }>
                          Edit
                          </Button>
                        </Grid>
                        <Grid item xs={3} style={{ paddingLeft: 5  }}>
                          <Button disabled={this.state.checked.length > 0 ? false : true } fullWidth style={{ marginTop: 10 }} variant="outlined" color="secondary" onClick={()=> this.onRemoveSortableListItem() }>
                          Delete
                          </Button>
                        </Grid>
                        {this.renderTransition()}
                      </Grid>
                    </Grid>
                    <Grid style={{ height:windowHeight - 205, position: 'relative', overflow: 'scroll' }} item xs={12}>
                        {this.renderSortable()}
                    </Grid>
                </Grid>
                <Grid container alignItems="center" justify="center" style={{ backgroundColor: '#efefef' , height: windowHeight, padding: 10}} item xs={6}>
                    <Grid container spacing={0} direction="column" alignItems="center" justify="flex-start" style={{ height: 1920/4, width: 1080/4, backgroundColor: '#262626', marginBottom: 10}} >
                      {this.renderTemplate()}
                    </Grid>
                </Grid>
                <Grid style={{ height: windowHeight, padding: 10 }} item xs={3}>
                  <Grid container>
                    <Grid style={{ paddingRight: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="secondary" onClick={()=> this.handleSaveCampaign()}>
                      Save
                      </Button>
                    </Grid>
                    <Grid style={{ paddingLeft: 5, paddingRight: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onPreviewClick()}>
                      Preview
                      </Button>
                    </Grid>
                    <Grid style={{ paddingLeft: 5 }} item xs={4}>
                      <Button disabled={this.disablePreview()} fullWidth style={{ marginTop: 10 }} variant="outlined" color="default" onClick={()=> this.onClearClick()}>
                      Clear
                      </Button>
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    margin="dense"
                    id="standard-name"
                    label="Campaign Name"
                    value={this.state.campaignName}
                    onChange={this.handleCampaignNameChange}
                  />
                  <Grid container>
                    <DayPicker 
                      switchChecked = {this.state.switchChecked}
                      handleSwitchChange={() => this.handleSwitchChange()}
                      selectedStartTime={this.state.selectedStartTime} 
                      selectedEndTime={this.state.selectedEndTime} 
                      selectedStartDate={this.state.selectedStartDate} 
                      selectedEndDate={this.state.selectedEndDate} 
                      openDialogStartDate={() => this.openDialogStartDate()} 
                      openDialogEndDate={() => this.openDialogEndDate()} 
                      openDialogStartTime={() => this.openDialogStartTime()} 
                      openDialogEndTime={() => this.openDialogEndTime()} 
                      closeDialog={() => this.closeDialog()}/>
                  </Grid>
                </Grid>
            </Grid>
          </div>
        );
    }
}

CustomizedAddCampaign.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default compose(
  withStyles(styles),
  ReactTimeout
)(CustomizedAddCampaign);
  