import React, { Component } from 'react';
import CustomizedStationTable from '../components/CustomizedStationTable';
import firebase from "firebase"

class Stations extends Component {

    state = {
      stationData: [],
      isMobile: false
    }

    componentWillMount(){
      let ref = firebase.database().ref('station');

      ref.on('value', snapshot => {
        const stationData = [];

        snapshot.forEach(childSnapshot => {
          const item = childSnapshot.val();
          item.key = childSnapshot.key;

          //if(item.zone === 'default')
          //{
            stationData.push({ 
              id:item.key, 
              name: item.name, 
              created: item.created, 
              updated: item.updated, 
              campaign: item.campaign ? item.campaign : null,
              connected: item.connected,
              zone: item.zone,
              used: item.used,
              app_version: item.app_version ? item.app_version : "1.0.0",
              is_playing: item.is_playing ? true : false,
              push_date: item.push_date ? item.push_date : "0000000000",
            });
          //}
        
        });

        const windowSize = window.innerWidth;
        if(windowSize < 800){
          this.setState({ isMobile: true, stationData })
        } else {
          this.setState({ isMobile: false, stationData })
        }

      });

      window.addEventListener("resize", this.handleResize );
    }

    handleResize  = () => {
      const windowSize = window.innerWidth;
      if(windowSize < 800){
        this.setState({ isMobile: true })
      } else {
        this.setState({ isMobile: false })
      }
    }

    render() {
      return (
          <div style={{ padding: 0 }}>
           <CustomizedStationTable 
              rows={this.state.isMobile ? [
                { id: 'name', numeric: false, disablePadding: true, label: 'Station Name', sortable: true },
                { id: 'connected', numeric: false, disablePadding: false, label: 'Status', sortable: true },
                { id: 'is_playing', numeric: false, disablePadding: false, label: 'Play', sortable: true },
                { id: 'app_version', numeric: false, disablePadding: false, label: 'App Version', sortable: true }
              ] : [
                { id: 'name', numeric: false, disablePadding: true, label: 'Station Name', sortable: true },
                { id: 'connected', numeric: false, disablePadding: false, label: 'Status', sortable: true },
                { id: 'is_playing', numeric: false, disablePadding: false, label: 'Play', sortable: true },
                { id: 'app_version', numeric: false, disablePadding: false, label: 'App Version', sortable: true },
                { id: 'action', numeric: false, disablePadding: false, sortable: false, action: true },
              ]} 
              data={this.state.stationData}
              orderBy={'created'}
              movefile={false}
              dataName='station'
              isMobile={this.state.isMobile}/>
          </div>
      );
    }
}
  
export default Stations;